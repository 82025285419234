var gmaps = require( 'google-maps' );
gmaps.KEY = 'AIzaSyDFq2vrcPCb8Tv_ULC50QYdeyufE9IiMWU';

if ( document.getElementById( 'maps' ) ) {

  ( function() {

    gmaps.load( function( google ) {
      var map;

      function initialize() {
        var glat = 50.824304;
        var glong = 4.732896;
        var icon = {
          url: '/bundles/sitefrontend/img/placeholder.svg',
          scaledSize: new google.maps.Size(35, 35)
        };
        var mapOptions = {
          zoom: 11,
          scrollwheel: false,
          center: new google.maps.LatLng( glat, glong )
        };
        map = new google.maps.Map( document.getElementById( 'maps' ), mapOptions );

        var marker = new google.maps.Marker( {
          position: new google.maps.LatLng( glat, glong ),
          map: map,
          icon: icon
        } );
      }

      google.maps.event.addDomListener( window, 'load', initialize );
    } );
  } )();

}
