var fb = $('.js-fb');
var fbb = $('.js-fbb');

var mmb = $('.js-btn');
var mm = $('.js-mobile-nav');

mmb.on('click', function(event) {
  event.preventDefault();
  $(this).toggleClass('isToggled');
  mm.toggleClass('isOpen');
});

fbb.on('click', function(event) {
  event.preventDefault();
  fb.toggleClass('isOpen');
});
