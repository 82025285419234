(function ($) {
    var d = new Date(new Date().getTime());
    var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

    //FASTBOOKER
    var arrival = $('.js-arrival');
    var departure = $('.js-departure');
    arrival.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        container: '.fastbooker',
        orientation: 'auto',
        weekStart: 1
    }).on('changeDate', function (e) {
        departure.focus();
        var selectedDate = arrival.datepicker('getDate');
        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        departure.datepicker('setDate', nextDay);
        departure.datepicker('setStartDate', nextDay);
    });
    departure.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        container: '.fastbooker',
        orientation: 'auto',
        weekStart: 1
    });
    var btn = $('.js-fastbooker-btn');
    var locale = $('html').attr('lang');
    btn.click(function (e) {
        e.preventDefault();
        var arrival = $('#arrival').val();
        arrival = arrival.split('/');
        arrival = arrival[2] + '-' + arrival[1] + '-' + arrival[0];
        var departure = $('#departure').val();
        var discount = $('#discount').val();
        departure = departure.split('/');
        departure = departure[2] + '-' + departure[1] + '-' + departure[0];
        var href = "https://reservations.cubilis.eu/bremberg-hotel-haasrode/Rooms/Select?" + "Arrival=" + arrival + "&Departure=" + departure  + "&DiscountCode=" + discount + getLangCode(locale); 
        if ((arrival !== 'undefined-undefined-') && (departure !== 'undefined-undefined-')) {
            href = "https://reservations.cubilis.eu/bremberg-hotel-haasrode/Rooms/Select?" + "Arrival=" + arrival + "&Departure=" + departure  + "&DiscountCode=" + discount + getLangCode(locale);
        }
        window.open(href, '_blank');
    });


    function getLangCode(locale){
    var langCode = {
      'nl': 'nl-BE',
      'fr': 'fr-BE',
      'en': 'en-GB',
      'de': 'de-DE',
    }[locale];
    return '&Language=' + langCode;
  }

})(jQuery);
